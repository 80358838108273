import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Media from "react-media";

// Context
import { ProjectsActiveFilterConsumer } from "../context/projects-active-filter";

// Components
import DesktopMenu from "../navigation/desktop-menu";
import MobileMenu from "../navigation/mobile-menu";
import { PageNotFoundDesktopMenu } from "../navigation/desktop-page-not-found-menu";
import { PageNotFoundMobileMenu } from "../navigation/mobile-page-not-found-menu";

// Styles
import { borderColor } from "../styles/colors";

const HeaderWrapper = styled.header`
  position: ${props => props.position};
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 0 20px;

  background-color: #fff;
  border-bottom: 1px solid ${borderColor};
  z-index: 200;

  line-height: 35px;

  @media (max-width: 767px) {
    padding: 0 10px;

    line-height: 35px;
  }
`;

const Header = ({ location, data, pageTitle, doesPathExist }) => {
  const [windowWidth, setWindowWidth] = useState(768);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let getWindowWidth = window.innerWidth;
      setWindowWidth(getWindowWidth);
    }
  }, []);

  return (
    <HeaderWrapper
      position={
        location.pathname === `/projects` || location.pathname === `/projects/`
          ? `sticky`
          : `fixed`
      }
    >
      {doesPathExist ||
      location.pathname === `/` ||
      location.pathname === `/studio` ||
      location.pathname === `/studio/` ||
      location.pathname === `/research` ||
      location.pathname === `/research/` ||
      location.pathname === `/privacy-policy` ||
      location.pathname === `/privacy-policy/` ||
      location.pathname === `/projects` ||
      location.pathname === `/projects/` ? (
        <ProjectsActiveFilterConsumer>
          {({ projectsActiveFilter, setProjectsActiveFilter }) => (
            <>
              <Media
                queries={{ medium: "(max-width: 768px)" }}
                defaultMatches={{ medium: windowWidth === 768 }}
                render={() => (
                  <MobileMenu
                    links={data.site.siteMetadata.menuLinks}
                    text={data.prismicMenu.data.mobile_menu_text}
                    location={location.pathname}
                    filters={data.prismicProjectsMenu.data.items}
                    projectsActiveFilter={projectsActiveFilter}
                    setProjectsActiveFilter={setProjectsActiveFilter}
                  />
                )}
              />

              <Media
                queries={{ medium: "(min-width: 769px)" }}
                defaultMatches={{ medium: windowWidth === 769 }}
                render={() => (
                  <DesktopMenu
                    links={data.site.siteMetadata.menuLinks}
                    location={location.pathname}
                    title={pageTitle}
                    filters={data.prismicProjectsMenu.data.items}
                    projectsActiveFilter={projectsActiveFilter}
                    setProjectsActiveFilter={setProjectsActiveFilter}
                  />
                )}
              />
            </>
          )}
        </ProjectsActiveFilterConsumer>
      ) : (
        <>
          <Media
            queries={{ medium: "(max-width: 768px)" }}
            defaultMatches={{ medium: windowWidth === 768 }}
            render={() => (
              <PageNotFoundMobileMenu
                links={data.site.siteMetadata.menuLinks}
                text={data.prismicMenu.data.mobile_menu_text}
              />
            )}
          />

          <Media
            queries={{ medium: "(min-width: 769px)" }}
            defaultMatches={{ medium: windowWidth === 769 }}
            render={() => (
              <PageNotFoundDesktopMenu
                links={data.site.siteMetadata.menuLinks}
              />
            )}
          />
        </>
      )}
    </HeaderWrapper>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
        prismicProjectsMenu {
          data {
            items {
              tag
            }
          }
        }
        prismicMenu {
          data {
            mobile_menu_text {
              html
            }
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
);
