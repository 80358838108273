import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Components
import ProjectsTableHeader from "../projects/projects-table-header";

// Styles
import { linkColor } from "../styles/colors";

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;

  line-height: 1;
  padding: 12px 0 9px 0;

  & nav {
    width: 251px;

    order: 3;

    & ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      & .active-link {
        color: #000;
      }

      &:hover > li > a {
        color: ${linkColor};
      }

      &:hover > li > a:hover {
        color: #000;
      }
    }

    @media (max-width: 1160px) {
      order: 2;
    }
  }

  & ul {
    padding: 0;
    margin: 0;
  }

  & ul,
  & li {
    list-style: none;
  }

  & li {
    padding-right: 20px;

    &:last-of-type {
      padding-right: 0;
    }

    & a {
      color: ${linkColor};
    }
  }

  & h3 {
    font-size: 25px;
  }
`;

const Logo = styled.li`
  width: 251px;
  order: 1;

  & a {
    color: #000 !important;

    &:hover {
      color: ${linkColor} !important;
    }
  }

  & a > strong {
    margin-top: -3px;
    display: inline-block;
  }
`;

const Title = styled.div`
  order: 2;

  @media (max-width: 1160px) {
    order: 3;

    width: 100%;
    justify-content: flex-start;

    padding: 13px 0 0 0 !important;
  }

  & h3 {
    font-size: 15px;
  }
`;

const Filters = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  order: 2;

  text-align: center;

  & li {
    list-style: none;
    padding: 0 7.5px;

    & button {
      line-height: 1;
    }
  }

  &:hover > li > button {
    color: ${linkColor};
  }

  @media (max-width: 1160px) {
    order: 3;
    width: 100%;
    justify-content: flex-start;

    padding: 13px 0 0 0 !important;

    & li:first-of-type {
      padding: 0 7.5px 0 0;
    }
  }

  @media (max-width: 767px) {
    padding: 0 20px 20px;

    justify-content: center;
  }
`;

const List = styled.li`
  &:hover > button {
    color: #000 !important;
  }
`;

const FilterButton = styled.button`
  color: ${props => (props.isActiveFilter ? "#000000" : linkColor)};
`;

const DesktopMenu = ({
  links,
  location,
  title,
  filters,
  projectsActiveFilter,
  setProjectsActiveFilter,
}) => {
  const allFilters = filters.map(filter => (
    <List
      key={`filter_${filter.tag}`}
      className={projectsActiveFilter === filter.tag ? "active-link" : ""}
    >
      <FilterButton
        onClick={() => setProjectsActiveFilter(filter.tag)}
        isActiveFilter={projectsActiveFilter === filter.tag}
      >
        {filter.tag}
      </FilterButton>
    </List>
  ));

  return (
    <>
      <Menu>
        <Logo>
          {(location === `/` ||
            location === `/studio` ||
            location === `/studio/` ||
            location === `/research` ||
            location === `/research/` ||
            location === `/privacy-policy` ||
            location === `/privacy-policy/` ||
            location === `/projects` ||
            location === `/projects/`) && <Link to={`/`}>Wolfe Hall</Link>}

          {location.includes(`/projects/`) && location !== "/projects/" && (
            <Link to={`/projects`}>
              <strong>←</strong> Back to Projects
            </Link>
          )}

          {location.includes(`/research/`) && location !== "/research/" && (
            <Link to={`/research`}>
              <strong>←</strong> Back to Research
            </Link>
          )}
        </Logo>

        {(location === `/projects` || location === `/projects/`) && (
          <Filters>{allFilters}</Filters>
        )}

        {location.includes(`/projects/`) && location !== "/projects/" && (
          <Title
            dangerouslySetInnerHTML={{
              __html: title.html,
            }}
          />
        )}

        {location.includes(`/research/`) && location !== "/research/" && (
          <Title
            dangerouslySetInnerHTML={{
              __html: title.html,
            }}
          />
        )}

        <nav>
          <ul>
            {links.map(link => (
              <li key={link.name}>
                <Link
                  to={link.link}
                  activeClassName="active-link"
                  className={location === "/" ? `active-link` : ""}
                  partiallyActive={true}
                >
                  {link.name}
                </Link>
              </li>
            ))}
            <li key="email">
              <a
                href={`mailto:info@wolfehall.com`}
                className={location === "/" ? `active-link` : ""}
              >
                Email
              </a>
            </li>
          </ul>
        </nav>
      </Menu>
      {(location === `/projects` || location === `/projects/`) && (
        <ProjectsTableHeader />
      )}
    </>
  );
};

export default DesktopMenu;
