import React from "react";
import { Helmet } from "react-helmet";
import { Location } from "@reach/router";
import styled from "styled-components";
import { BaseCSS, GridThemeProvider } from "styled-bootstrap-grid";
import { Normalize } from "styled-normalize";

// Components
import GlobalStyles from "../styles/globalStyles";
import App from "./app";
import DefaultSEO from "./default-seo";

const Container = styled.div`
  position: relative;

  display: flex;

  // min-height: ${props => (props.location === "/" ? "" : "100vh")};

  flex-direction: column;

  width: 100%;

  overflow: ${props => (props.location === "/" ? "hidden" : "")};
`;

const gridTheme = {
  breakpoints: {
    xl: 1200,
    lg: 992,
    md: 766,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 12.5,
  },
  col: {
    padding: 12.5,
  },
};

export const Layout = ({ children }) => (
  <GridThemeProvider gridTheme={gridTheme}>
    <Location>
      {({ location }) => {
        // if (typeof window !== `undefined`) {
        //   if (location.pathname === "/") {
        //     document.body.style.overflow = "hidden";
        //   } else {
        //     document.body.style = "";
        //   }
        // }

        return (
          <Container location={location.pathname}>
            <Helmet>
              <link
                rel="icon"
                href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🐺</text></svg>"
              />
              <meta
                name="viewport"
                content="width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1"
              />
              <link
                rel="stylesheet"
                href="https://use.typekit.net/wpg5mhy.css"
              />
            </Helmet>
            <Normalize />
            <BaseCSS />
            <GlobalStyles />
            <DefaultSEO location={location} />
            <App location={location} children={children} />
          </Container>
        );
      }}
    </Location>
  </GridThemeProvider>
);

export default Layout;
