import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Styles
import { linkColor } from "../styles/colors";

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;

  line-height: 1;
  padding: 12px 0 9px 0;

  & nav {
    width: 251px;

    order: 3;

    & ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      & .active-link {
        color: #000;
      }

      &:hover > li > a {
        color: ${linkColor};
      }

      &:hover > li > a:hover {
        color: #000;
      }
    }

    @media (max-width: 1160px) {
      order: 2;
    }
  }

  & ul {
    padding: 0;
    margin: 0;
  }

  & ul,
  & li {
    list-style: none;
  }

  & li {
    padding-right: 20px;

    &:last-of-type {
      padding-right: 0;
    }

    & a {
      color: ${linkColor};
    }
  }

  & h3 {
    font-size: 25px;
  }
`;

const Logo = styled.li`
  width: 251px;
  order: 1;

  & a {
    color: #000 !important;

    &:hover {
      color: ${linkColor} !important;
    }
  }

  & a > strong {
    margin-top: -3px;
    display: inline-block;
  }
`;

export const PageNotFoundDesktopMenu = ({ links }) => (
  <Menu>
    <Logo>
      <Link to={`/`}>Wolfe Hall</Link>
    </Logo>
    <nav>
      <ul>
        {links.map(link => (
          <li key={link.name}>
            <Link
              to={link.link}
              activeClassName="active-link"
              className={`active-link`}
              partiallyActive={true}
            >
              {link.name}
            </Link>
          </li>
        ))}
        <li key="email">
          <a href={`mailto:info@wolfehall.com`} className={`active-link`}>
            Email
          </a>
        </li>
      </ul>
    </nav>
  </Menu>
);
