import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: 'Starling Arrow';
    src: url('/fonts/Starling-Arrow.eot');
    src: url('/fonts/Starling-Arrow.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Starling-Arrow.woff2') format('woff2'),
        url('/fonts/Starling-Arrow.woff') format('woff'),
        url('/fonts/Starling-Arrow.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

  *:focus{
    outline: none
  }

  body {
    color: #000;
    background-color: #fff;

    font-family: starling, serif;
    font-style: normal;
    font-weight: 300;
    font-feature-settings: "liga";

    font-size: 15px;
    line-height: 21px;

    letter-spacing: 0.05px;

    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;

    text-shadow: 1px 1px 1px rgba(0,0,0,.004);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  b,
  strong {
    font-family: 'Starling Arrow';
    font-weight: normal;
  }

  em {
  font-style: italic;
  letter-spacing: 0.3px;
  }

  a,
  a:visited {
    text-decoration: none;
    color: inherit;
  }

  a:focus{
    outline: none;
  }

  a {
    & > strong{
      font-family: 'Starling Arrow';
    }
  }

  h1, h2, h3, h4, h5, h6{
    margin: 0;
    font-weight: normal;
  }

  h1 {
  }

  h2{
  }

  h3{
  }


  button {
    cursor: pointer;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    border: 0;
    background-color: transparent;

    color: #000;

    &:hover {
      color: #000;
    }

    &:focus {
      outline: 0;
    }

  }

  img {
    width: 100%;
    display: block;
  }


/*--------------------------------------------------------------
  # Cookie Consent
  --------------------------------------------------------------*/


.cookie-consent-container{
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items: center;

  position: fixed;

  top: 36px !important;
  left:0;

  width:100%;

  font-family: starling, serif;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.05px;


  background:#ffffff;
  border-top: 1px solid #666666;
  border-bottom: 1px solid #666666;

  color:#000;

  padding: 9px 20px 6px 20px;
  z-index:2000;

  @media(max-width: 768px){
    padding: 9px 10px 6px 10px
  }

  & div {
    width: calc(100% - 70px);
  }

  & a {
    text-decoration: underline;
    text-decoration-color: #666666;

    &:hover{
      color: #a2a2a2;
    }
  }

  & button{
    color: #000;

    align-self: flex-start;

    margin: 2px 0 0 0;
    padding: 0;

    &:hover{
      color: #a2a2a2;
    }
  }
}
`;

export default GlobalStyle;
