import React from "react";
import styled from "styled-components";
import CookieConsent from "react-cookie-consent";
import { useStaticQuery, graphql, Link } from "gatsby";

// Context
import {
  PageTitleProvider,
  PageTitleConsumer,
} from "../context/page-title-context";

import {
  ProjectsActiveFilterProvider,
  ProjectsActiveFilterConsumer,
} from "../context/projects-active-filter";

import { ProjectsTableContextProviderComponent } from "../context/projects-table-header-filter";

// Components
import Header from "./header";

const Main = styled.main`
  flex: 1;
`;

const App = ({ location, data, children }) => {
  const paths = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `);

  const doesPathExist =
    paths.allSitePage.nodes.filter(node => node.path === location.pathname)
      .length >= 1;

  return (
    <ProjectsTableContextProviderComponent>
      <PageTitleProvider>
        <PageTitleConsumer>
          {({ pageTitle }) => (
            <ProjectsActiveFilterProvider>
              <ProjectsActiveFilterConsumer>
                {({ projectsActiveFilter }) => (
                  <>
                    <Header
                      location={location}
                      pageTitle={pageTitle}
                      projectsActiveFilter={projectsActiveFilter}
                      doesPathExist={doesPathExist}
                    />

                    <CookieConsent
                      location="top"
                      buttonText="✕"
                      declineButtonText="Decline"
                      cookieName="gatsby-gdpr-google-analytics"
                      containerClasses="cookie-consent-container"
                      disableStyles={true}
                    >
                      This website uses cookies to enhance the user experience.
                      Close this dialog to confirm you are happy with that, or
                      find out more in our{" "}
                      <Link to={`/privacy-policy`}>privacy policy</Link>.
                    </CookieConsent>
                    <Main>{children}</Main>
                  </>
                )}
              </ProjectsActiveFilterConsumer>
            </ProjectsActiveFilterProvider>
          )}
        </PageTitleConsumer>
      </PageTitleProvider>
    </ProjectsTableContextProviderComponent>
  );
};

export default App;
