import React from "react";
import styled from "styled-components";

// Components
import ContextConsumer from "../context/projects-table-header-filter";

//Styles
import { borderColor } from "../styles/colors";

const TableHeader = styled.div`
  position: relative;

  width: 100vw;

  background-color: #fff;
  border-top: 1px solid ${borderColor};

  margin: 0 -20px;

  @media (max-width: 767px) {
    margin: 0 -10px;
  }

  line-height: 1;

  z-index: 100;

  & ul {
    display: grid;
    grid-template-columns: 2fr 3fr 2fr minmax(50px, 200px) minmax(50px, 100px);

    padding: 0;
    margin: 0;

    @media (max-width: 900px) {
      grid-template-columns: 2fr 3fr 2fr 1fr;
    }

    @media (max-width: 800px) {
      grid-template-columns: 2fr 3fr 1fr;
    }

    @media (max-width: 767px) {
      grid-template-columns: 1fr 70px;
    }
  }

  & li {
    position: relative;
    list-style: none;
    padding: 12px 14px 9px 14px;

    margin: 0;

    &::after {
      content: "";
      height: 12px;
      border-right: 1px solid ${borderColor};

      position: absolute;
      right: 0;
      bottom: 0;
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }

    &:first-of-type,
    &:nth-of-type(2) {
      padding: 12px 14px 9px 20px;

      @media (max-width: 767px) {
        padding: 12px 10px 9px 10px;
      }
    }

    &:last-of-type {
      padding: 12px 20px 9px 14px;

      @media (max-width: 767px) {
        padding: 12px 10px 9px 10px;
      }
    }
  }
`;

const HeaderCol = styled.li`
  cursor: pointer;

  display: ${props => (props.largeScreen ? props.largeScreen : "block")};

  & .arrow {
    font-family: "Starling Arrow";
    line-height: 1;

    margin-top: -3px;
    display: inline-block;

    &::before {
      content: " ";
      display: inline;
    }
  }

  @media (max-width: 900px) {
    display: ${props => (props.mediumScreen ? props.mediumScreen : "block")};
  }

  @media (max-width: 800px) {
    display: ${props => (props.tabletScreen ? props.tabletScreen : "block")};
  }

  @media (max-width: 767px) {
    display: ${props => (props.mobileScreen ? props.mobileScreen : "block")};
  }
`;

const ProjectsTableHeader = () => (
  <ContextConsumer>
    {({ activeTableFilter, set }) => (
      <TableHeader>
        <ul>
          <HeaderCol
            largeScreen={`none`}
            mediumScreen={`none`}
            tabletScreen={`none`}
            mobileScreen={`block`}
            onClick={() =>
              set(activeTableFilter === "client" ? "-client" : "client")
            }
          >
            <>Project</>

            {activeTableFilter === "client" && <span className="arrow">↓</span>}
            {activeTableFilter === "-client" && (
              <span className="arrow">↑</span>
            )}
          </HeaderCol>

          <HeaderCol
            mobileScreen={`none`}
            onClick={() =>
              set(activeTableFilter === "client" ? "-client" : "client")
            }
          >
            <>Client</>

            {activeTableFilter === "client" && <span className="arrow">↓</span>}
            {activeTableFilter === "-client" && (
              <span className="arrow">↑</span>
            )}
          </HeaderCol>

          <HeaderCol
            mobileScreen={`none`}
            onClick={() =>
              set(activeTableFilter === "title" ? "-title" : "title")
            }
          >
            <>Project</>

            {activeTableFilter === "title" && <span className="arrow">↓</span>}
            {activeTableFilter === "-title" && <span className="arrow">↑</span>}
          </HeaderCol>

          <HeaderCol
            tabletScreen={`none`}
            mobileScreen={`none`}
            onClick={() => set(activeTableFilter === "tags" ? "-tags" : "tags")}
          >
            <>Outcome</>

            {activeTableFilter === "tags" && <span className="arrow">↓</span>}
            {activeTableFilter === "-tags" && <span className="arrow">↑</span>}
          </HeaderCol>

          <HeaderCol
            mediumScreen={`none`}
            tabletScreen={`none`}
            mobileScreen={`none`}
            onClick={() =>
              set(
                activeTableFilter === "discipline"
                  ? "-discipline"
                  : "discipline"
              )
            }
          >
            <>Discipline</>

            {activeTableFilter === "discipline" && (
              <span className="arrow">↓</span>
            )}
            {activeTableFilter === "-discipline" && (
              <span className="arrow">↑</span>
            )}
          </HeaderCol>

          <HeaderCol
            onClick={() =>
              set(
                activeTableFilter === "project_year"
                  ? "-project_year"
                  : "project_year"
              )
            }
          >
            Year
            {activeTableFilter === "-project_year" && (
              <span className="arrow">↓</span>
            )}
            {activeTableFilter === "project_year" && (
              <span className="arrow">↑</span>
            )}
          </HeaderCol>
        </ul>
      </TableHeader>
    )}
  </ContextConsumer>
);

export default ProjectsTableHeader;
