import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useState } from "react";
import "array-flat-polyfill";

// Styles
import { linkColor } from "../styles/colors";

// Components
import ProjectsTableHeader from "../projects/projects-table-header";

const Menu = styled.div`
  width: 100%;

  line-height: 1;
  padding: 12px 0 9px 0;

  & ul,
  & li {
    list-style: none;
    margin: 0;
  }

  & ul {
    display: flex;
    flex-direction: row;

    padding: 0;
  }

  & li {
    padding-right: 20px;

    &:last-of-type {
      padding-right: 0;
    }

    & > a {
      color: ${linkColor};
    }

    & .active-link {
      color: #000;
    }
  }
`;

const StudioText = styled.div`
  font-size: 16px;
  line-height: 21px;

  letter-spacing: 0.05px;

  @media (max-width: 500px) {
    padding: 0 50px 0 0;

    & p {
      margin: 20px 0 10px 0;
    }
  }
`;

const Filters = styled.ul`
  display: block !important;
  flex-direction: unset !important;

  padding: 15px 0 5px 0 !important;
  margin: 0;

  &:hover li > button {
    color: ${linkColor};
  }

  &:hover li > button:hover {
    color: #000;
  }

  & li {
    list-style: none;
    padding-right: 0;

    line-height: 25px;

    &:last-of-type {
      padding-right: 0;
    }
  }
`;

const FilterButton = styled.button`
  color: ${props => (props.isActiveFilter ? "#000" : linkColor)};
`;

const MenuToggle = styled.button`
  line-height: 1;

  padding: ${props => (props.padding ? props.padding : "")};
  color: ${props => (props.isActiveLink ? linkColor : `#000`)};
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${props => props.justifyContent};

  width: 100%;

  & > a,
  & > ul {
    margin: ${props => props.adjustLogoMargin};
  }
`;

const MobileMenu = ({
  links,
  text,
  location,
  filters,
  projectsActiveFilter,
  setProjectsActiveFilter,
}) => {
  const [isMenuOpen, toggleMenuOpen] = useState(false);
  const [isDisciplineOpen, toggleDisciplineOpen] = useState(false);

  const allFilters = filters.map(filter => (
    <li key={`filter_${filter.tag}`}>
      <FilterButton
        onClick={() => {
          setProjectsActiveFilter(filter.tag);
          toggleDisciplineOpen(false);
        }}
        isActiveFilter={projectsActiveFilter === filter.tag}
      >
        {filter.tag}
      </FilterButton>
    </li>
  ));

  return (
    <>
      <Menu>
        <Nav
          justifyContent={
            location === `/projects` || location === `/projects/`
              ? `flex-end`
              : "space-between"
          }
          adjustLogoMargin={
            location === `/projects` || location === `/projects/`
              ? `0 auto 0 0`
              : "0"
          }
        >
          {isMenuOpen === true ? (
            <ul>
              {links.map(link => (
                <li key={link.name}>
                  <Link
                    to={link.link}
                    onClick={() => toggleMenuOpen(false)}
                    className={location === "/" ? `active-link` : ""}
                    partiallyActive={true}
                    activeClassName="active-link"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <>
              <Link to={`/`}>Wolfe Hall</Link>
              {(location === `/projects` || location === `/projects/`) && (
                <MenuToggle
                  onClick={() => toggleDisciplineOpen(!isDisciplineOpen)}
                  padding={`0 15px 0 0`}
                  isActiveLink={isDisciplineOpen}
                >
                  Filter {isDisciplineOpen ? "–" : "+"}
                </MenuToggle>
              )}
            </>
          )}

          <MenuToggle
            onClick={() => {
              toggleMenuOpen(!isMenuOpen);
              toggleDisciplineOpen(false);
            }}
          >
            Menu {isMenuOpen ? "–" : "+"}
          </MenuToggle>
        </Nav>

        {isDisciplineOpen === true && <Filters>{allFilters}</Filters>}

        {isMenuOpen === true && (
          <StudioText
            dangerouslySetInnerHTML={{
              __html: text.html,
            }}
          />
        )}
      </Menu>
      {(location === `/projects` || location === `/projects/`) && (
        <ProjectsTableHeader />
      )}
    </>
  );
};

export default MobileMenu;
